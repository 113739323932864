.dynamic-tabs {
  position: relative;
  padding: 0;
  margin-top: 10px;
  .MuiTab-labelIcon {
    min-height: 48px;
  }
  .tabs-module_filled_tab_body__qZ3CX {
    padding: 0;
  }
  .tabs-module_default__Alac5 .tabs-module_default_tab_body__Viw7u{
    padding: 16px 0;
  }
}

.tab-revert-steps {
  height: 302px;
}

.fixed-tabs {
  position: sticky !important;
  left: 0;
  width: 100px;
  min-width: 100px;
  z-index: 1;
  background-color: white !important;
  & tr td:first-child{
    border-right: 1px solid #DEDBDB
  }
}

.text-for-basic-tab{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding-right: 5px;
}