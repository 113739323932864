.search {
  display: flex;
  padding-top: 10px;
  .main-row {
    display: flex;
    width: 100%;
    .second-row {
      display: grid;
      flex-direction: column;
      width: 100%;
      .grid-matrix {
        grid-column: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .channel {
          display: flex;
          p {
            width: max-content;
          }
          .input {
            width: 280px;
            margin-left: 15px;
          }
          input {
            font-size: 16px;
          }
        }
        .checkin {
          display: flex;
          width: max-content;
          .date {
            margin-top: -5px;
            min-width: 100px;
            width: 150px;
            max-width: 160px;
          }
          p {
            margin-right: 10px;
          }
        }
        .number_input {
          display: flex;
          p {
            margin-right: 10px;
            width: 50px;
          }
          input {
            width: 100px;
          }
        }
        .legacy {
          display: flex;
          p {
            margin-left: 15px;
            padding-right: 15px;
          }
          .input {
            width: 250px;
            margin-left: 15px;
            margin-top: 10px;
          }
          input {
            font-size: 16px;
          }
        }
        .optional {
          padding-top: 5px;
        }
        .search-items {
          align-items: center;
          margin-left: 15px;
        }
      }
      .input-button {
        grid-column: 2;
        padding-left: 15px;
        margin-right: 15px;
        flex-wrap: nowrap;
        .inspect {
          display: flex;
          justify-content: right;
          padding-top: 5px;
        }
      }
    }
  }
}

.invalid {
  border: 1px solid #bd0024 !important;
}

.id-invalid {
  .input-module_custom_input__3eAR_ {
    border: 1px solid #bd0024 !important;
  }
}

@media (max-width: 1919px) {
  .search {
    .main-row {
    }
  }
}

@media (max-width: 1820px) {
}

@media (max-width: 1440px) {
  .initial-container {
    padding-top: 40px;
  }
}

@media (max-width: 1112px) {
  .initial-container {
    padding-top: 60px;
  }
}

@media (max-width: 900px) {
  .search {
    .main-row {
      .second-row {
        display: flex;
        .input-button {
          display: flex;
          grid-column: 0;
          justify-content: right;
          .inspect {
            display: flex;
          }
        }
      }
    }
  }
}


