.accordion-body {
    display: list-item !important;
    list-style: none;
}

.MuiAccordionDetails-root.accordion-body {
    padding: 0 !important;
}

.accordion-items {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.accordion-items.MuiAccordion-root.Mui-expanded {
    margin: 0;
    border-radius: 4px;
    .accordion-items.MuiAccordion-root.Mui-expanded.MuiAccordionSummary-root {
        border-radius: 4px 4px 0 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
    }
    .MuiAccordionSummary-root {
        border-radius: 4px 4px 0 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }
}
.accordion-items.MuiAccordion-root:before {
    background-color: #d4d4d4;
}

.accordion-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .label-principal {
        .MuiTypography-body1 {
            font-size: 1.3rem;
        }
    }
    .icon-principal {
        padding-top: 5px;
    }
}

.icons-accordion {
    padding: 5px 0 5px 0;
    justify-content: space-between;
    display: flex;
    .labels {
        display: flex;
        .label {
            height: 20px;
            background-color: #7f7f7f;
            margin-right: 15px;
            color: #faf9f9;
            font-style: normal;
        }
    }
}

.box-container {
    overflow: hidden !important;
    border: 1px solid #dedbdb;
    border-radius: 6px;
}

.icons {
    .pointer {
        cursor: pointer;
    }
    .money {
        margin-left: 10px;
    }
    .icon-loading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 75px;
        padding-bottom: 40px;
    }
}

.chips-with-icon{
    padding-left: 10px;
}

.chips-without-icon{
    padding-top: 1px;
}