@import '../../styles/brand-colors';

.vacasa-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, -1) !important;

    .vacasa-modal-card {
        width: 1000px;
        height: 900px !important;
        display: flex;
        flex-direction: column;
        outline: none;

        .vacasa-modal-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
            border-bottom: 1px solid $dusk-lightest;
            padding-left: 16px;
            padding-right: 16px;

            p {
                color: #707579;
                font-size: 18px;
                font-weight: bold;
            }

            svg > path {
                fill: #bfc2c3;
            }
        }

        .vacasa-modal-body {
            height: 100%;
            padding-left: 8px;
            padding-right: 8px;
            overflow-y: auto;
        }
    }
}
