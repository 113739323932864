@import 'styles/brand-colors';
@import 'styles/font-styles';

body {
    background-color: transparent;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    padding: 0 75px;
    zoom: 90%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.pointer {
    &:hover {
        cursor: pointer;
    }
}
.pointer-disabled {
    pointer-events: none;
    opacity: 0.2;
}

.as-link {
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
}

.check-icon {
    fill: $success;
    stroke: $success;
}

.x-icon {
    fill: $danger;
    stroke: $danger;
}

// Always show the scroll
::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
