.container {
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: rgba(44, 52, 57, 0.15) 1px 1px 20px;
    margin-left: 14px;
    margin-top: -8px;
    height: 100%;
    padding: 8px 0 0 0 !important;
    .container-header {
        background-color: white;
        display: flex;
        flex-direction: row;
        padding-left: 18px;
        margin-top: -5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        .title {
            background-color: white;
            border: none;
            margin-top: 8px;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
        }
        .imagen-header {
            padding: 12px 15px 0 0;
            justify-content: center;
            .pointer {
                cursor: pointer;
            }
        }
    }
    .container-body {
        padding-left: 18px;
        position: relative;
        max-height: 863.5px;
        float: left;
        overflow: hidden;
        overflow-y: auto;
        width: 95%;
        .title-body {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            padding: 15px 0 15px 0;
            margin-top: 0;
            display: flex;
            justify-content: center;
        }
        .item-container-fees {
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            &:last-child {
                border-bottom: none;
                margin-bottom: 15px;
            }
            .item {
                display: flex;
                flex-direction: row;
                margin-top: 16px;
                margin-bottom: 16px;
            }
            .tag {
                height: 20px;
                background-color: #7f7f7f;
                color: #faf9f9;
            }
            .title-onclick {
                color: #0078ab;

            }
        }
        .item-container-taxes {
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            &:last-child {
                border-bottom: none;
                margin-bottom: 50px;
            }
            .item {
                display: flex;
                flex-direction: row;
                margin-top: 16px;
                margin-bottom: 16px;
            }
            .tag {
                height: 20px;
                background-color: #7f7f7f;
                color: #faf9f9;
            }
            .title-onclick {
                color: #0078ab;
            }
        }
        .tax {
            margin-top: 15px;
        }
    }
}
