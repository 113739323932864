@import "../../styles/brand-colors";

.MuiContainer-root {
  padding: 0 !important;
}

.nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 32px;
  background-color: $midnight-100;
  box-shadow: 2px 0 5px $black;

  .nav-bar-title {
    h1 {
      color: $dusk-one;
    }
  }

  .nav-bar-links {
    display: flex;
    flex-direction: row;

    div {
      &:after {
        content: "";
        top: 5px;
        bottom: 0;
        right: 0;
        margin-left: 8px;
        margin-right: 8px;
        border-right: 1px solid $gray-five;
      }
      &:last-child:after {
        display: none;
      }
    }
    a {
      color: $dusk-thirty;
      text-decoration: none;
    }

    .selected {
      color: $dusk-one;
      font-weight: bold;
    }
  }

  .temp-class {
    display: none;
  }
}

.infinite-scrolling {
  max-height: 212px !important;
}

.table-class {
  border-collapse: separate !important;
  border-top: 1px solid grey;
}

.header {
  padding: 18px 0 !important;
  background-color: white !important;
  .MuiSvgIcon-root.MuiTableSortLabel-icon.MuiTableSortLabel-iconDirectionAsc {
    opacity: 1;
  }
  .icon-loading {
    display: inline-block;
    width: 72px;
    padding-bottom: 20px;
    margin-left: -65px;
  }
}

.fixed {
  position: sticky !important;
  left: 0;
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  z-index: 1;
  background-color: white !important;
  border-right: 1px solid black;
}

.first-header-for-tab {
  border-right: 1px solid #dedbdb;
  z-index: 3 !important;
  display: list-item;
}

.label-priority {
  margin-left: 5px;
}

.pointer {
  cursor: pointer;
}

.wrap {
  width: 100px;
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}

.not-order {
  ~ svg {
    display: none;
  }
}
