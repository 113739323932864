.title {
  background-color: #f5f5f5;
  min-height: 30px;
  border: 1px solid #f5f5f5;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  span {
    padding-left: 15px;
  }
}

.body {
  background-color: white;
}

.search {
  width: 100%;
}

.accordion {
  padding: 0 15px 10px 15px;
  .MuiAccordion-root {
    margin: 10px 0;
    background-color: #f5f5f5;
  }
  .MuiCollapse-container {
    background-color: white !important;
    border-radius: 0 0 4px 4px;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px !important;
    height: 54.2px !important;
    background-color: white;
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
  .MuiPaper-elevation3 {
    box-shadow: rgba(44, 52, 57, 0.15) 1px 1px 20px;
  }
  .MuiAccordion-root.Mui-expanded:first-child {
    margin-top: 10px !important;
  }
  .MuiAccordionSummary-content {
    transition: none !important;
  }
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter {
  width: 12%;
}

.accordion-items.MuiAccordion-root:before {
  height: 0 !important;
}

.modal-loading {
  margin-top: 30px;
  height: 100px;
  min-height: 100px;
}

.view-more {
  height: 75px;
  min-height: 130px;
  .button-view-more {
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }
}

.whitespace-fill {
  display: flex;
  background-color: white;
  margin-top: 30px;
  height: 100px;
}